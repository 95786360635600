<template>
    <v-sheet class="transparent">
        <!-- 제목 -->
        <v-sheet outlined class="ma-2 pa-4">
            <font class="font-weight-bold text-h6 grey--text text--darken-2">운영진 권한 관리</font>
        </v-sheet>

        <!-- 본문 -->
        <v-sheet outlined class="pa-2 ma-2">
            <!-- 검색 -->
            <v-sheet outlined class="pa-2 mb-2">
                <v-sheet class="d-flex" width="660">
                    <!-- 유형 -->
                    <v-sheet width="180" class="mr-3">
                        <v-select
                            outlined
                            dense
                            hide-details
                            :items="select_list.search_type"
                            v-model="search_type"
                        ></v-select>
                    </v-sheet>

                    <!-- 키워드 -->
                    <v-text-field
                        placeholder="검색어를 입력해주세요"
                        outlined
                        dense
                        hide-details
                        v-model="keyword"
                        @keyup.enter="search()"
                    ></v-text-field>

                    <!-- 제출 버튼 -->
                    <v-btn
                        height="40"
                        class="px-6 ml-3"
                        color="blue"
                        dark
                        depressed
                        @click="search()"
                    >
                        <v-icon small left>mdi-magnify</v-icon>
                        검색하기
                    </v-btn>
                </v-sheet>
            </v-sheet>

            <!-- 목록 -->
            <v-data-table
                id="list"
                :headers="headers"
                :items="list"
                :footer-props="{
                    'items-per-page-options': [10, 20, 100, -1],
                    'items-per-page-text': '페이지당 운영진 수'
                }"
                no-data-text="현재 데이터가 없습니다."
            >
                <template v-slot:item="{ item }">
                    <tr>
                        <td style="width:320px"><font class="d-block text-truncate" style="width:320px;">{{item.user_id}}</font></td>
                        <td style="width:100%"><font class="d-block text-truncate" style="width:280px;">{{item.nickname}}</font></td>
                        <td style="width:240px">
                            <div style="width:240px; padding-bottom:3px;">
                                <v-checkbox
                                    v-model="item.permission_pointmall"
                                    hide-details
                                    class="mt-0"
                                    :label="item.permission_pointmall? '권한 배정':' - '"
                                    @change="update_permission(item, 'pointmall')"
                                ></v-checkbox>
                            </div>
                        </td>
                        <td style="width:240px">
                            <div style="width:240px; padding-bottom:3px;">
                                <v-checkbox
                                    v-model="item.permission_official_league"
                                    hide-details
                                    class="mt-0"
                                    :label="item.permission_official_league? '권한 배정':' - '"
                                    @change="update_permission(item, 'official_league')"
                                ></v-checkbox>
                            </div>
                        </td>
                        <td style="width:240px">
                            <div style="width:240px; padding-bottom:3px;">
                                <v-checkbox
                                    v-model="item.permission_deck_recipe"
                                    hide-details
                                    class="mt-0"
                                    :label="item.permission_deck_recipe? '권한 배정':' - '"
                                    @change="update_permission(item, 'deck_recipe')"
                                ></v-checkbox>
                            </div>
                        </td>
                        <td style="width:140px">
                            <div style="width:140px;">
                                <v-btn
                                    width="120"
                                    outlined
                                    color="blue"
                                    block
                                    @click="update_to_normal(item)"
                                >
                                    권한 해제
                                </v-btn>
                            </div>
                        </td>
                    </tr>
                </template>
            </v-data-table>

            <!-- 선택항목 & 버튼 -->
            <v-card class="mt-2 pa-2 d-flex justify-end" outlined>
                <v-btn
                    class="px-6 ml-3"
                    color="blue"
                    large
                    dark
                    depressed
                    @click="dialog.add_staff = true"
                >
                    <v-icon small left>mdi-database-plus-outline</v-icon>
                    운영진 추가하기
                </v-btn>
            </v-card>
        </v-sheet>

        <!-- 포인트 지급하기 -->
        <v-dialog v-model="dialog.add_staff" width="440" content-class="rounded-15">
            <v-sheet class="rounded-15 pa-4">
                <p class="pt-3 mb-2 font-weight-bold text-center text-h6 grey--text text--darken-2">
                    운영진 추가하기
                </p>
                <v-sheet class="mt-2">
                    <v-sheet
                        outlined
                        class="pa-1 mt-4 mx-auto"
                        style="width:340px;"
                    >
                        <v-text-field
                            v-model="add_staff_user_id"
                            class="shrink"
                            hide-details
                            dense
                            solo
                            flat
                            placeholder="추가할 운영진 아이디를 입력해주세요"
                        ></v-text-field>
                    </v-sheet>
                </v-sheet>
                <v-sheet class="mt-2 pa-2 d-flex justify-center">
                    <v-btn
                        class="px-6 mr-4"
                        color="blue"
                        large
                        dark
                        depressed
                        @click="add_staff_submit()"
                    >
                        <v-icon small left>mdi-database-plus-outline</v-icon>
                        추가하기
                    </v-btn>
                    <v-btn
                        class="px-6"
                        color="grey"
                        large
                        dark
                        depressed
                        @click="dialog.add_staff = false"
                    >
                        <v-icon small left>mdi-cancel</v-icon>
                        취소하기
                    </v-btn>
                </v-sheet>
            </v-sheet>
        </v-dialog>
    </v-sheet>
</template>
<script>
export default {
    data: () => ({
        headers: [
            {text: '운영진 아이디', value: 'user_id'},
            {text: '운영진 닉네임', value: 'nickname'},
            {text: '포인트몰 관리 권한', value: 'permission_pointmall'},
            {text: '공식대회 관리 권한', value: 'permission_official_league'},
            {text: '덱레시피 관리 권한', value: 'permission_deck_recipe'},
            {text: '권한 해제', value: ''},
        ],

        list: [],

        select_list: {
            search_type: [
                "운영진 아이디", 
                "운영진 닉네임", 
            ]
        },

        search_type: "운영진 아이디",
        keyword: "",

        add_staff_user_id: "",

        dialog: {
            add_staff: false
        }
    }),

    mounted(){
        this.search()
    },

    methods: {
        // 목록
        // load(){
        //     this.$http.post('/api/admin/staff/permission/list')
        //     .then((res) => {
        //         this.list = res.data
        //     })
        // },

        // 검색
        search(){
            this.$http.post('/api/admin/staff/permission/search', {
                params: {
                    keyword: this.keyword,
                    type: this.search_type
                }
            }).then((res) => {
                this.list = res.data
            })
        },

        // 관리 권한 업데이트
        update_permission(item, type){
            let permission_value = 0
            if(type == "pointmall"){
                permission_value = item.permission_pointmall
            }else if(type == "official_league"){
                permission_value = item.permission_official_league
            }else if(type == "deck_recipe"){
                permission_value = item.permission_deck_recipe
            }

            this.$http.post('/api/admin/staff/permission/update', {
                params: {
                    type: type,
                    user_id: item.user_id,
                    permission_value: permission_value
                }
            })
        },

        // 권한 해제
        update_to_normal(item){
            if(confirm(item.user_id+"의 권한을 해제하시겠습니까?")){
                this.$http.post('/api/admin/staff/permission/update/type', {
                    params: {
                        user_id: item.user_id,
                        type: "사용자"
                    }
                }).then((res) => {
                    alert("해당 운영진의 권한이 해제되었습니다.")
                    this.search()
                })
            }
        },

        // 운영진 추가
        add_staff_submit(){
            this.$http.post('/api/admin/staff/permission/update/type', {
                params: {
                    user_id: this.add_staff_user_id,
                    type: "운영진"
                }
            }).then((res) => {
                if(res.data.affectedRows == 1){
                    alert("해당 운영진이 추가되었습니다.")
                    this.add_staff_user_id = ""
                    this.dialog.add_staff = false
                    this.search()
                }else{
                    alert("해당 아이디에 해당하는 회원이 없습니다.")
                }
            })

        }
    }
}
</script>
<style>
#list{
    border:1px solid #efefef;
}
#list tr th{
    background:#f7f7f7;
}

#list tr th, #list tr td{
    width:85px;
    padding:0 5px;
    padding-left:10px;
}

#list tr th:not(:last-child), #list tr td:not(:last-child){
    border-right:1px solid #efefef;
}

#selected_user{
    width:100%;
    border:1px solid #ccc;
    border-collapse: collapse;
}

#selected_user tr th{
    height:20px;
    padding:12px 20px;
    border:1px solid #ccc;
    background: #f7f7f7;
}

#selected_user tr td{
    height:20px;
    padding:12px 20px;
    border:1px solid #ccc;
}
</style>